import React, { useEffect, useState } from 'react';
import { Link, useOutletContext, useSearchParams } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import certified from '../../../../assets/ui/managed.svg';
import bug from '../../../../assets/ui/bug-grey-4.svg';

import getCompetingCompanies from '../../../../utils/getCompetingCompanies';
import getLocalLink from '../../../../utils/getLocalLink';

import './CompetingCompanies.scss';
import loadBootstrapTooltips from '../../../../utils/loadBootstrapTooltips';
import Status from './Status';

function CompetingCompanies({ profile, partnerId, climateAction, status, selectedCategory }) {
  const [competingCompanies, setCompetingCompanies] = useState([]);
  const [searchParams] = useSearchParams();

  const { isDashboard } = useOutletContext();

  const { t } = useTranslation();

  useEffect(() => {
    getCompetingCompanies(profile.id, partnerId, selectedCategory?.id).then((response) => {
      if (status && response) {
        response.sort((a, b) => b.status_position - a.status_position);
      }

      if (climateAction && response) {
        response.sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }));
      }

      setCompetingCompanies(response);
      loadBootstrapTooltips();
    });
  }, [profile]);

  return (
    competingCompanies.length > 0 ? (
      <div className="white-bg mb-4 p-4 tw-border tw-border-solid tw-border-greySix tw-rounded">
        <h1 className="tw-text-greyOne tw-text-base d-flex align-items-middle justify-content-start mb-4s">
          {t('COMPETING_COMPANIES_TITLE')} {selectedCategory ? `${t('CATEGORY').toLowerCase()} ${selectedCategory.name.toLowerCase()}` : ''}
        </h1>
        <div className="row mx-0">
          {competingCompanies.slice(0, 8).map((company, index) => (
            <Link
              to={`${getLocalLink(`${window.origin}${isDashboard ? '/dashboard/suppliers' : '/profile'}/${company.slug}`)}${searchParams.get('cf') ? `${searchParams.get('partner') ? '&' : '?'}cf=${searchParams.get('cf')}` : ''}`}
              key={index}
              className="d-flex flex-column competing-company-item text-decoration-none primary-LLL-hover pt-3 px-0"
              onClick={(() => window.scrollTo(0, 0))}
            >
              <div className="d-flex flex-row">
                <div>
                  <img src={`${process.env.REACT_APP_FILES_DOMAIN}${company.logo_path}${company.logo_name}`} alt={company.name} className="competing-company-logo img-fluid" loading="lazy" />
                </div>
                <div className="d-flex flex-column justify-content-center ms-2">
                  <div className="tw-flex tw-items-center tw-font-medium tw-text-greyOne tw-text-sm">
                    {company.name}
                    {climateAction ? (
                      <div>
                        {company.isManagedSort ? (
                          <img src={certified} alt="Certified" className="tw-w-[20px] tw-ml-[4px] -tw-mt-1" />
                        ) : (
                          <img src={bug} alt="Profile publique" className="tw-w-[22px] tw-ml-[2px] -tw-mt-1" />
                        )}
                      </div>
                    ) : ('')}
                  </div>
                  <span className="tw-text-xs tw-font-medium tw-text-greyFour tw-font-moskauGrotesk tw-mt-2">
                    {climateAction ? (
                      <div>
                        {company.isManagedSort ? (
                          t('COMPETING_COMPANIES_MEMBER')
                        ) : (
                          t('COMPETING_COMPANIES_NOT_MEMBER')
                        )}
                      </div>
                    ) : ('')}
                  </span>
                  {status ? (
                    <Status profile={company} />
                  ) : ('')}
                </div>
              </div>
              <div className="tw-border tw-border-solid tw-border-b tw-border-t-0 tw-border-x-0 tw-border-greySix tw-mt-4" />
            </Link>
          ))}
          <div className="pt-4 grey-HH-color">
            <Link to={`${getLocalLink(window.origin + isDashboard ? '/dashboard/suppliers' : '')}${searchParams.get('cf') ? `${searchParams.get('partner') ? '&' : '?'}cf=${searchParams.get('cf')}` : ''}`} className="tw-text-sm tw-text-greyFour" loading="lazy">
              {t('SEE_ALL_COMPANIES_BY_SECTOR')}
            </Link>
          </div>
        </div>
      </div>
    ) : ('')
  );
}

export default CompetingCompanies;
