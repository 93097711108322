import React from 'react';
import { useTranslation } from 'react-i18next';

function ProfileCatagoryData({ currentProfile, register, errors, tonnageGlobal, turnoverGlobal, partnerName }) {
  const { t } = useTranslation();

  return (
    <>
      <div className="tw-mt-8 tw-p-4 tw-border tw-border-solid tw-border-greySix tw-rounded-t tw-font-moskauGrotesk">
        <div className="tw-text-lg tw-font-medium tw-text-greyThree tw-mb-6">
          {t('CARBON_WEIGHT_AND_TURNOVER', { partner: partnerName })}
        </div>
        <div className="tw-grid tw-grid-cols-3 tw-gap-2.5 tw-text-greyOne tw-max-h-96 tw-overflow-y-auto tw-text-sm">
          <div className="tw-col-span-1 tw-font-medium tw-text-greyFour">
            {t('CATEGORY')}
          </div>
          <div className="tw-col-span-1 tw-font-medium tw-text-greyFour">
            {t('TONNAGE')}
          </div>
          <div className="tw-col-span-1 tw-font-medium tw-text-greyFour">
            {t('CA')}
          </div>
          {currentProfile && currentProfile.categories?.map((category, index) => (
            <React.Fragment key={category.id + category.carbon_weight + index}>
              <div className="tw-col-span-1 tw-items-center tw-flex tw-text-greyOne">
                {category.name}
              </div>
              <div className={`tw-flex tw-col-span-1 tw-rounded-md tw-mb-2.5 tw-mr-[1px] ${errors.carbon_weight ? 'parent-outline-red-error' : 'parent-outline'}`}>
                <input
                  id="carbon_weight"
                  type="number"
                  step=".001"
                  min={0}
                  className={`custom-number-input tw-border-l tw-border-r-0 tw-border-t tw-border-b tw-border-solid tw-rounded-l-md tw-text-greyFour tw-px-5 tw-py-2.5 tw-font-normal tw-text-base tw-w-full ${errors.carbon_weight ? 'tw-border-redError focus:tw-outline-none focus:tw-border-l focus:tw-border-t focus:tw-border-b focus:tw-border-redError' : 'tw-border-greySix focus:tw-outline-none focus:tw-border-l focus:tw-border-t focus:tw-border-b focus:tw-border-secondarySkyBlue'}`}
                  {...register(`carbon_weight_${category.id}`)}
                />
                <span className={`tw-pr-4 tw-flex tw-items-center tw-h-full tw-self-center tw-border-solid tw-border-l-0 tw-border-r tw-border-t tw-border-b ${errors.carbon_weight ? 'tw-border-redError' : 'tw-border-greySix'} tw-rounded-r-md tw-text-greyFour tw-text-sm`}>
                  ktCO2e
                </span>
              </div>
              <div className={`tw-flex tw-col-span-1 tw-rounded-md tw-mb-2.5 tw-mr-[1px] ${errors.carbon_weight ? 'parent-outline-red-error' : 'parent-outline'}`}>
                <input
                  id="turnover"
                  type="number"
                  step=".001"
                  min={0}
                  className={`custom-number-input tw-border-l tw-border-r-0 tw-border-t tw-border-b tw-border-solid tw-rounded-l-md tw-text-greyFour tw-px-5 tw-py-2.5 tw-font-normal tw-text-base tw-w-full ${errors.turnover ? 'tw-border-redError focus:tw-outline-none focus:tw-border-l focus:tw-border-t focus:tw-border-b focus:tw-border-redError' : 'tw-border-greySix focus:tw-outline-none focus:tw-border-l focus:tw-border-t focus:tw-border-b focus:tw-border-secondarySkyBlue'}`}
                  {...register(`turnover_${category.id}`)}
                />
                <span className={`tw-pr-4 tw-flex tw-items-center tw-h-full tw-self-center tw-border-solid tw-border-l-0 tw-border-r tw-border-t tw-border-b ${errors.turnover ? 'tw-border-redError' : 'tw-border-greySix'} tw-rounded-r-md tw-text-greyFour tw-text-sm`}>
                  M€
                </span>
              </div>
            </React.Fragment>
          ))}
        </div>
      </div>
      <div className="tw-flex tw-justify-between tw-bg-greyEight tw-border-t-0 tw-border tw-border-solid tw-border-x-greySix tw-border-b-greySix tw-px-4 tw-py-3.5 tw-text-greyTwo tw-text-sm tw-items-center">
        <div className="tw-text-greyFour tw-font-medium">
          {t('TONNAGE_GLOBALE')}
        </div>
        <div className="tw-text-secondarySkyBlue tw-font-medium tw-text-base tw-font-">
          {tonnageGlobal.toFixed(2)} ktCO2e
        </div>
      </div>
      <div className="tw-flex tw-justify-between tw-bg-greyEight tw-border-t-0 tw-border tw-border-solid tw-border-x-greySix tw-border-b-greySix tw-px-4 tw-py-3.5 tw-rounded-b tw-text-greyTwo tw-text-sm tw-items-center">
        <div className="tw-text-greyFour tw-font-medium">
          {t('TURNOVER_GLOBALE')}
        </div>
        <div className="tw-text-secondarySkyBlue tw-font-medium tw-text-base tw-font-">
          {turnoverGlobal.toFixed(2)} M€
        </div>
      </div>
    </>
  );
}

export default ProfileCatagoryData;
